import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Inicio from './pages/Inicio';
import Estoque from './pages/Estoque';
import Contato from './pages/Contato';
import Veiculo from './pages/Veiculo';
import Financiamento from './pages/Financiamento';
import VendaSeuVeiculo from './pages/VendaSeuVeiculo';

class App extends Component {
  render(){
    return (
      <Router>
          <Switch>  
              <Route path='/' exact component={Inicio} />                  
              <Route path='/veiculo/:idVeiculo' component={Veiculo} />
              <Route path='/contato' component={Contato} />                  
              <Route path='/financiamento' component={Financiamento} />                  
              <Route path='/estoque' component={Estoque} />                  
              <Route path='/vendaseuveiculo' component={VendaSeuVeiculo} />         
              <Route path='/:idempresa' component={Inicio} />         
          </Switch> 
      </Router>
    );
  }
}

export default App;
